<template>
  <div id="advanced-search__form">
    <div v-if="canRun" class="search__actions tw-flex tw-justify-end tw-mb-2">
      <b-button
        id="search__clear-all"
        variant="outline-primary"
        class="mr-1"
        @click="handleClearClick"
      >
        <span class="align-middle">{{ $t('Clear all') }}</span>
      </b-button>
      <b-button
        id="search__run"
        :class="{ 'icon-disabled': !selectedEntities.length }"
        variant="primary"
        @click="selectedEntities.length && handleRun()"
      >
        <span class="align-middle">{{ $t('Run') }}</span>
      </b-button>
      <b-tooltip
        v-if="!selectedEntities.length"
        target="search__run"
        placement="left"
      >
        {{ $t('advanced-search-disabled-run-text') }}
      </b-tooltip>
    </div>
    <div v-if="viewMode" class="search__actions tw-flex tw-justify-end tw-mb-2">
      <b-button
        id="search__edit"
        :class="{ 'icon-disabled': !canUpdate }"
        variant="primary"
        @click="
          canUpdate &&
            $router.push({
              name: 'advanced-search-view.edit',
              params: {
                id: $route.params.id,
                queryType: $route.params.queryType,
              },
            })
        "
      >
        <span class="align-middle">{{ $t('Edit') }}</span>
      </b-button>
      <b-tooltip v-if="!canUpdate" target="search__edit" placement="left">
        {{ $t(`no-edit-${$route.params.queryType}-query-permission-text`) }}
      </b-tooltip>
    </div>
    <div
      class="search__columns tw-flex"
      :class="{ 'layout--expanded-results': isRunning }"
    >
      <!-- START: Selector columns -->
      <div
        v-show="showSelectors"
        class="search__column--selectors tw-flex"
        :class="{ 'pointer-events-none': viewMode }"
      >
        <ProzessEntitySelector
          :collapsable="isRunning"
          :collapsed="columns.tablesCollapsed"
          :view-mode="viewMode"
          :options="entityOptions"
          :selections="selectedEntities"
          @collapse-change="
            (collapsed) => (columns.tablesCollapsed = !collapsed)
          "
          @item-click="(item) => handleTableClick(item)"
        >
          <template slot="collapsed-content">
            {{ selectedTablesCount }} {{ $t('tables selected') }}
          </template>
        </ProzessEntitySelector>
        <ProzessEntityFieldSelector
          store="advancedSearch"
          :collapsable="isRunning"
          :collapsed="columns.fieldsCollapsed"
          :view-mode="viewMode"
          :options="fieldOptions"
          toggleable
          :toggle-text="$t('Filter')"
          @collapse-change="
            (collapsed) => (columns.tablesCollapsed = !collapsed)
          "
        >
          <template slot="collapsed-content">
            {{ selectedFieldsCount }} {{ $t('fields selected') }}
          </template>
        </ProzessEntityFieldSelector>
        <div class="search__column column--filters tw-mt-4">
          <div class="tw-mb-4 tw-flex tw-justify-between">
            <h4 class="mb-0 tw-text-body">{{ $t('Filters') }}</h4>
            <span
              v-if="isRunning"
              class="tw-cursor-pointer"
              @click="columns.filtersCollapsed = !columns.filtersCollapsed"
            >
              <small>
                {{ !columns.filtersCollapsed ? $t('Hide') : $t('Show') }}
              </small>
              <feather-icon
                :icon="
                  !columns.filtersCollapsed
                    ? 'ChevronUpIcon'
                    : 'ChevronDownIcon'
                "
                class="tw-ml-2"
              />
            </span>
          </div>
          <div
            v-show="!columns.filtersCollapsed"
            class="column__content tw-overflow-auto tw-p-6 tw-rounded-lg tw-relative"
            :class="{ 'filters--empty': !selectedFiltersCount }"
          >
            <div v-if="viewMode" class="selectors__backdrop tw-absolute" />
            <div
              v-if="!filterOptions.length"
              class="tw-text-lg tw-text-center tw-text-muted tw-mt-5"
            >
              {{ $t('No Filters Available') }}
            </div>
            <AdvancedSearchTableFilters
              v-for="(item, i) in filterOptions"
              :key="i"
              :entity-id="item.entityId"
            />
          </div>
          <div
            v-show="columns.filtersCollapsed"
            class="column__content tw-overflow-auto tw-p-6 tw-rounded-lg"
            :class="{ content__collapsed: columns.filtersCollapsed }"
          >
            {{ selectedFiltersCount }} {{ $t('filters selected') }}
          </div>
        </div>
      </div>
      <!-- END: Selector columns -->
      <!-- START: Results column -->
      <div
        class="search__column column--results tw-mt-4"
        :class="{ 'results--full': !showSelectors }"
      >
        <h4
          class="tw-mb-4 tw-flex tw-inline-block tw-text-body"
          :class="{ 'tw-cursor-pointer': isRunning }"
          @click="toggleSelectorsVisibility"
        >
          <!-- START: Shown when screen width is below xxl -->
          <feather-icon
            v-if="isRunning"
            :icon="showSelectors ? 'ChevronsLeftIcon' : 'ChevronsRightIcon'"
            size="16"
            class="tw-mr-2 chevrons--horizontal"
          />
          <!-- END: Shown when screen width is below xxl -->
          <!-- START: Shown when screen width is xxl and above -->
          <feather-icon
            v-if="isRunning"
            :icon="showSelectors ? 'ChevronsUpIcon' : 'ChevronsDownIcon'"
            size="16"
            class="tw-mr-2 chevrons--vertical"
          />
          <!-- END: Shown when screen width is xxl and above -->
          {{ $t('Results') }}
        </h4>
        <div class="column__content tw-p-6 tw-rounded-lg">
          <div
            v-if="!showResultsTable"
            class="tw-text-lg tw-text-center tw-text-muted tw-mt-5"
          >
            {{ $t('No Results Loaded') }}
          </div>
          <AdvancedSearchResults
            v-show="showResultsTable"
            :form-action="formAction"
            :resource="resource"
          />
        </div>
      </div>
      <!-- END: Results column -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { Entity } from '@/constants/app'
import { searchModelSchema } from '@/schema/advancedSearch'
import { getFilterConditionKey } from '@/helpers/advancedSearch'
import { FormAction, QueryType } from '@/constants/advancedSearch'
import advancedSearchService from '@/services/advancedSearch'
import AdvancedSearchTableFilters from '@/components/AdvancedSearch/AdvancedSearchTableFilters.vue'
import AdvancedSearchResults from '@/components/AdvancedSearch/AdvancedSearchResults.vue'
import RootEvents from '@/constants/rootEvents'
import ProzessEntitySelector from '@/components/shared/ProzessEntitySelector.vue'
import ProzessEntityFieldSelector from '@/components/shared/ProzessEntityFieldSelector.vue'
import prozessEntitySelectorMixin from '@/mixins/prozessEntitySelector'

export default {
  components: {
    AdvancedSearchTableFilters,
    AdvancedSearchResults,
    ProzessEntitySelector,
    ProzessEntityFieldSelector,
  },

  mixins: [
    /**
     * !! ATTENTION !!
     * Read the comments on top of the `@/mixins/prozessEntitySelector.js` file first before implementing this mixin.
     *
     * DO NOT DELETE THIS COMMENT.
     */
    prozessEntitySelectorMixin('advancedSearch'),
  ],

  data() {
    return {
      resource: null,
      FormAction,
      isRunning: false,
      entityOptions: [],
      showSelectors: true,
      columns: {
        tablesCollapsed: false,
        fieldsCollapsed: false,
        filtersCollapsed: false,
      },
    }
  },

  computed: {
    ...mapState({
      entities: ({ advancedSearch }) => advancedSearch.entities,
      selectedEntities: ({ advancedSearch }) => advancedSearch.selectedEntities,
      fieldOptions: ({ advancedSearch }) => advancedSearch.fieldOptions,
      result: ({ advancedSearch }) => advancedSearch.result,
    }),

    ...mapGetters({
      primaryEntityId: 'advancedSearch/primaryEntityId',
      filterOptions: 'advancedSearch/filterOptions',
      selectedTablesCount: 'advancedSearch/selectedTablesCount',
      selectedFieldsCount: 'advancedSearch/selectedFieldsCount',
      selectedFiltersCount: 'advancedSearch/selectedFiltersCount',
      filteringFields: 'advancedSearch/filteringFields',
    }),

    showResultsTable() {
      return this.isRunning
    },

    formAction() {
      switch (this.$route.name) {
        case 'advanced-search.create':
          return FormAction.CREATE

        case 'advanced-search-view.edit':
          return FormAction.EDIT

        default:
          return this.$route.params.action
      }
    },

    canUpdate() {
      const queryType = this.$route.params.queryType === QueryType.GLOBAL
        ? 'Global_Query'
        : 'Personal_Query'

      return this.$can('Update', queryType)
    },

    viewMode() {
      return [FormAction.VIEW, FormAction.RUN].includes(this.formAction)
    },

    canRun() {
      return [FormAction.CREATE, FormAction.EDIT].includes(this.formAction)
    },
  },

  watch: {
    '$route.name': {
      handler(value) {
        if (!value.startsWith('advanced-search')) {
          return null
        }

        this.resetLocalState()
        this.resetStore()
        this.$nextTick(async () => {
          await this.getEntities()

          if (this.$route.params.id) {
            this.getQueryData()
          }
        })
      },

      immediate: true,
    },
  },

  methods: {
    ...mapMutations({
      clearAll: 'advancedSearch/CLEAR_ALL',
      setErrors: 'advancedSearch/SET_ERRORS',
      updateFieldFilteringState: 'advancedSearch/UPDATE_FIELD_FILTERING_STATE',
      setFilterValue: 'advancedSearch/SET_FILTER_VALUE',
      setFilterCondition: 'advancedSearch/SET_FILTER_CONDITION',
    }),

    async getEntities() {
      const { response } = await this.$async(
        advancedSearchService.getEntities(),
      )

      const entities = response.data
        .filter(({ key }) => key !== Entity.DOCUMENT)
        .map(entity => {
          entity.id = `${entity.schema}.${entity.key}`
          return entity
        })

      this.setEntities(entities)
      this.resetEntityOptions()
    },

    async getQueryData() {
      const { queryType, id } = this.$route.params
      const { response } = await this.$async(
        advancedSearchService.getOneByType(queryType, id),
      )

      this.setInitialData(response.data)
    },

    handleClearClick() {
      this.resetLocalState()
      this.resetStore()
      this.$nextTick(() => this.getEntities())

      this.$root.$emit(RootEvents.ADV_SEARCH_CLEAR)
    },

    async handleRun() {
      const errors = await this.validateSearchModel()
      if (errors.length) {
        return false
      }

      this.isRunning = true
      this.$root.$emit(RootEvents.ADV_SEARCH_RUN)
    },

    resetLocalState() {
      this.isRunning = false
      this.columns.tablesCollapsed = false
      this.entityOptions = []
    },

    setInitialData(searchData) {
      this.resource = searchData

      this.$root.$emit(RootEvents.SET_PAGE_TITLE, this.resource.name)

      const entities = this.setInitialSelectedEntities(
        this.resource.searchModel,
      )

      this.$nextTick(() => {
        this.setInitialSelectedFields(entities)

        this.$nextTick(() => this.setInitialFilterValues(entities))

        if (this.formAction === FormAction.RUN) {
          this.$nextTick(() => this.handleRun())
        }
      })
    },

    setInitialSelectedEntities(searchModel) {
      const entities = searchModel.entities.map(entity => {
        const id = `${entity.schemaName}.${entity.tableName}`

        return {
          ...this.entities.find(({ id: entityId }) => entityId === id),
          ...entity,
        }
      })

      entities.forEach(entity => this.$nextTick(() => this.handleTableClick(entity)))

      return entities
    },

    setInitialSelectedFields(entities) {
      entities.forEach(entity => {
        this.toggleCollapsedState(entity.id)
        entity.selectedFields.forEach(field => {
          this.addSelectedField({ entityId: entity.id, fieldKey: field.key })
        })

        entity.filters.forEach(({ field }) => {
          this.updateFieldFilteringState({
            entityId: entity.id,
            fieldKey: field.key,
            isFiltering: true,
          })
        })
      })
    },

    setInitialFilterValues(entities) {
      entities.forEach(entity => {
        entity.filters.forEach(({ field, param, operation }) => {
          this.setFilterValue({
            filterValue: param?.value,
            entityId: entity.id,
            fieldKey: field.key,
          })

          this.setFilterCondition({
            filterCondition: getFilterConditionKey(operation),
            entityId: entity.id,
            fieldKey: field.key,
          })
        })
      })
    },

    toggleSelectorsVisibility() {
      if (!this.isRunning) {
        return
      }

      this.showSelectors = !this.showSelectors
    },

    async validateSearchModel() {
      const filters = this.filteringFields.reduce((_filters, field) => {
        const prefix = `${field.entitySchema}_${field.entityKey}_${field.key}`

        _filters[`${prefix}_input`] = field.filterValue
        _filters[`${prefix}_condition`] = field.filterCondition

        return _filters
      }, {})

      const errors = await this.yupValidate(
        searchModelSchema(this.filteringFields),
        filters,
      )

      this.setErrors(errors)

      return errors
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/master-variables.scss';

@mixin column-content__min-height {
  @include xxl {
    $approx-title-height: 30px;
    height: calc(100% - #{$approx-title-height});
  }
}

#advanced-search__form {
  .search__columns {
    flex-direction: column;

    .pz__entity-selector,
    .pz__entity-field-selector,
    .search__column {
      width: 100%;

      .column__content {
        background: var(--colour--card-bg);
      }

      &.column--filters {
        min-width: unset;
        .column__content {
          &:not(.filters--empty):not(.content__collapsed) {
            min-height: 320px;
          }
        }
      }

      &.column--results {
        .chevrons--horizontal {
          display: none;
        }

        .chevrons--vertical {
          display: inline-block;
        }
      }
    }

    .search__column--selectors {
      flex-direction: column;
      width: 100%;

      .selectors__backdrop {
        inset: 0;
        background: rgba($colour--black-light, 0.2);
        z-index: 3;
      }
    }
  }

  @include xxl {
    $approx-nav-footer-height: 210px;

    .search__columns {
      min-height: calc(100vh - #{$approx-nav-footer-height});
      flex-direction: row;

      &.layout--expanded-results {
        .search__column--selectors {
          max-width: 320px;
          min-width: 320px;
          flex-direction: column;
        }

        .pz__entity-field-selector {
          margin-left: 0;
          margin-right: 0;
        }

        .pz__entity-selector,
        .pz__entity-field-selector,
        .search__column {
          width: 100%;

          .column__content {
            height: auto;
          }

          &.column--filters {
            min-width: unset;
            .column__content {
              &:not(.filters--empty):not(.content__collapsed) {
                min-height: 320px;
              }
            }
          }

          &.column--results {
            width: calc(100% - 320px);

            &.results--full {
              width: 100%;
            }
          }
        }
      }

      .pz__entity-field-selector {
        margin-left: 1rem;
        margin-right: 1rem;
      }

      .pz__entity-selector,
      .pz__entity-field-selector,
      .search__column {
        width: 33.33%;

        .column__content {
          @include column-content__min-height;
        }

        &.column--filters {
          min-width: 420px;
          min-height: unset;
        }

        &.column--results {
          width: 15%;
          margin-left: 1rem;

          .column__content {
            @include column-content__min-height;
          }

          .chevrons--horizontal {
            display: inline-block;
          }

          .chevrons--vertical {
            display: none;
          }
        }
      }

      .search__column--selectors {
        flex-direction: row;
        width: 85%;
      }
    }
  }
}
</style>
